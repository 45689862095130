export const h1 = {
  letterSpacing: 'headings',
  css: ({ theme }) => ({
    fontSize: 32,
    fontWeight: 600,
    '@media only screen and (max-width: 600px)': {
      fontSize: theme.fontSizes.headersMobile
    }
  })
};

export const h2 = {
  color: 'primary',
  fontWeight: 'light',
  letterSpacing: 'headings',
  css: ({ theme }) => ({
    '@media only screen and (max-width: 600px)': {
      fontSize: theme.fontSizes.lg
    }
  })
};

export const h3 = {
  fontWeight: 'bold',
  fontSize: 'lg',
  letterSpacing: 'headings',
  variants: {
    animated: {
      css: {
        transition: 'all 0.3s ease'
      }
    }
  }
};

export const h4 = {
  fontWeight: 'bold',
  fontSize: 'introText',
  letterSpacing: 'headingsSecond',
  variants: {
    small: {
      fontWeight: 'bold',
      fontSize: 'tiny',
      color: 'baseGrey',
      letterSpacing: 'headingsSmall',
      css: {
        textTransform: 'uppercase'
      }
    },
    uppercase: {
      fontWeight: 'extraBold',
      fontSize: 'xs',
      letterSpacing: 'uppercase',
      css: {
        textTransform: 'uppercase'
      }
    }
  }
};
