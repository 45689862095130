import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Box, Portal, Backdrop, FlexLayout, Text } from '@deity/falcon-ui';
import { Icon } from '../Icon/Icon';

export const LoaderOverlay = () => (
  <Box
    as={Portal}
    css={{
      position: 'fixed',
      left: 0,
      width: '100vw',
      maxWidth: '100vw',
      top: 0,
      height: '100vh',
      // Needs to be this high to be on top of the modal and intercom button
      zIndex: 3147483000,
      pointerEvents: 'none'
    }}
  >
    <Backdrop visible />
    <Box
      bg="secondary"
      p="lg"
      borderRadius="sm"
      boxShadow="subtle"
      css={{
        display: 'inline-block',
        position: 'relative',
        left: '50%',
        top: '50%',
        // Needs to be this high to be on top of the modal and intercom button
        zIndex: 3147483000,
        transform: 'translate(-50%, -50%)'
      }}
    >
      <FlexLayout alignItems="center">
        <Icon src="loader" />
        <Text color="textDark">
          <T id="loading" />
        </Text>
      </FlexLayout>
    </Box>
  </Box>
);
