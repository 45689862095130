import React from 'react';
import { Box, Text } from '@deity/falcon-ui';

export const plans = {
  pretrial: {
    name: 'Pre-trial',
    id: 'plan_H5t5Xa97pgI0Cm'
  },
  free: {
    name: 'Free',
    id: 'plan_EwXuElkh44mwQO'
  },
  basic: {
    name: 'Basic',
    description: (
      <Box mb="md">
        <Text className="description" flex={1} fontSize="sm" color="textGrey">
          Engage with all your website visitors with web push notifications. Add your own brand and style, retain users
          and drive conversion.
        </Text>
        <Text fontWeight="semibold" fontSize="sm" color="textGrey" style={{ display: 'inline' }}>
          Perfect for non-profit or small local businesses to get started with PushPro.
        </Text>
      </Box>
    ),
    month: {
      ids: ['plan_H8bhTidhktuoXd', 'price_1Hf2p7I0jsznKljuXnhI7mIB'],
      current: {
        id: 'price_1Hf2p7I0jsznKljuXnhI7mIB',
        price: '15.00'
      }
    },
    year: {
      ids: ['plan_H8biLNBBc0Eh1L', 'price_1Hf2r1I0jsznKljuaXlEp8ST'],
      current: {
        id: 'price_1Hf2r1I0jsznKljuaXlEp8ST',
        price: '90.00'
      }
    }
  },
  bronze: {
    name: 'Starting',
    description: '',
    month: {
      ids: ['plan_EwY4YKTWEuESTF']
    },
    year: {
      ids: ['plan_EwY7RkP1fiaBRE']
    }
  },
  silver: {
    name: 'Growing',
    description: '',
    month: {
      ids: ['plan_EwY8I4LxP56Tah']
    },
    year: {
      ids: ['plan_EwY8jlzCe8jwFe']
    }
  },
  business: {
    name: 'Business',
    description: (
      <Box mb="md">
        <Text className="description" flex={1} fontSize="sm" color="textGrey">
          Target specific groups of people and see your click-through rates reach as high as 20%.
        </Text>
        <Text fontWeight="semibold" fontSize="sm" color="textGrey" style={{ display: 'inline' }}>
          Perfect for any online business that wants to accelerate growth.
        </Text>
      </Box>
    ),
    month: {
      ids: ['plan_H8bispbq8qOiYT', 'price_1Hf2soI0jsznKljuWndcdUXQ'],
      current: {
        id: 'price_1Hf2soI0jsznKljuWndcdUXQ',
        price: '85.00'
      }
    },
    year: {
      ids: ['plan_H8bisQUltoSC22', 'price_1HhybCI0jsznKljuQ9fycMgm'],
      current: {
        id: 'price_1HhybCI0jsznKljuQ9fycMgm',
        price: '500.00'
      }
    }
  },
  gold: {
    name: 'Booming',
    description: '',
    month: {
      ids: ['plan_EwY9a59DcMPHVx']
    },
    year: {
      ids: ['plan_EwYA8dLH8DjLST']
    }
  },
  unlimited: {
    name: 'Enterprise',
    description: (
      <Text className="description" mb="md" flex={1} fontSize="sm" color="textGrey">
        Gain access to an unlimited amount of subscribers and domains to drastically improve your marketing performance.
        Get quick feedback with our priority support.
      </Text>
    ),
    month: {
      ids: ['plan_H8bm4AGu4BQppn', 'price_1IO21jI0jsznKljuDqkuGHYu'],
      current: {
        id: 'price_1IO21jI0jsznKljuDqkuGHYu',
        price: '145.50'
      }
    },
    year: {
      ids: ['price_1IO1zgI0jsznKljuAAPaDkTm'],
      current: {
        id: 'price_1IO1zgI0jsznKljuAAPaDkTm',
        price: '0.00'
      }
    }
  }
};
