export const label = {
  fontSize: 'sm',
  fontWeight: 'semibold',
  variants: {
    subLabel: {
      color: 'baseGrey',
      fontSize: 'sm',
      lineHeight: 'normal',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.baseGrey
        }
      })
    }
  }
};
