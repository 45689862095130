export const cronBuilderCss = theme => ({
  '.cron-builder': {
    backgroundColor: theme.colors.secondaryLight,
    borderRadius: theme.borderRadius.sm,
    width: '100%',
    '&__fieldset': {
      borderColor: theme.colors.defaultBorder
    },
    '&__action': {
      background: theme.colors.defaultBorder,
      borderRadius: theme.borderRadius.xs,
      padding: theme.spacing.sm,
      color: theme.colors.defaultText,
      height: 'auto',
      fontSize: theme.fontSizes.sm,
      cursor: 'pointer'
    },
    '&__tab': {
      background: theme.colors.defaultBorder,
      backgroundImage: 'none',
      color: theme.colors.defaultText,
      '&--active': {
        background: theme.colors.primaryDark,
        color: theme.colors.white
      },
      '&:hover': {
        backgroundImage: 'none !important'
      }
    },
    '&__hr': {
      borderColor: theme.colors.primaryText
    },
    '&__pretty-expression': {
      color: theme.colors.primaryText
    }
  }
});
