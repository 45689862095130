export const divider = {
  borderColor: 'defaultBorder',
  variants: {
    small: {
      my: 'sm',
      css: {
        margin: '0 auto',
        borderTopWidth: '1px',
        maxWidth: '144px',
        borderRadius: '1px'
      }
    },
    smallTransparent: {
      my: 'sm',
      borderColor: 'transparentBlack',
      css: {
        margin: '0 auto',
        borderTopWidth: '1px',
        maxWidth: '144px',
        borderRadius: '1px'
      }
    },
    transparentBlack: {
      borderColor: 'transparentBlack',
      css: {
        margin: '0 auto',
        borderTopWidth: '2px',
        width: '216px',
        borderRadius: '1.5px'
      }
    }
  }
};
