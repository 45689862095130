export const reactCropCss = () => ({
  '.ReactCrop__image': {
    width: '100%',
    display: 'block',
    height: 'auto',
    maxWidth: 'unset',
    maxHeight: 'max-content',
    border: '2px dashed #0066FF'
  },
  '.Crop.dialog': {
    '.ReactCrop__image': {
      width: 'auto',
      maxHeight: '150px',
      display: 'block',
      overflow: 'hidden'
    }
  },
  '.checkerBoardBG': {
    backgroundImage:
      'linear-gradient(to right, rgba(192, 192, 192, 0.75), rgba(192, 192, 192, 0.75)), linear-gradient(to right, black 50%, white 50%), linear-gradient(to bottom, black 50%, white 50%)',
    backgroundBlendMode: 'normal, difference, normal',
    backgroundSize: '2em 2em',
    backgroundClip: 'padding-box'
  },
  '.ReactCrop__crop-selection, .ReactCrop--locked': {
    borderImage: 'none',
    border: '1px dashed rgba(0, 0, 0, 0.5)'
  }
});
