export const listItem = {
  variants: {
    clean: {
      py: 'xs',
      m: 'none',
      border: 'none',
      css: {
        listStyle: 'none'
      }
    }
  }
};
