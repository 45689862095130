export const group = {
  variants: {
    selector: {
      p: 'xxs',
      my: 'md',
      css: ({ theme }) => ({
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '6px',
        background: 'rgba(55, 60, 79, 0.05)',
        boxShadow: 'inset 0px 2px 1px rgba(0, 0, 0, 0.1)',
        '&.disabled': {
          pointerEvents: 'none',
          cursor: 'default',
          'button:not(.active)': {
            color: theme.colors.textLight
          }
        },
        button: {
          fontSize: theme.fontSizes.xs,
          paddingTop: theme.spacing.xs,
          paddingBottom: theme.spacing.xs,
          paddingLeft: theme.spacing.xsm,
          paddingRight: theme.spacing.xsm,
          color: theme.colors.textDark,
          borderRadius: `${theme.borderRadius.xs}px !important`,
          lineHeight: '16px',
          background: 'transparent',
          '&:hover:enabled': {
            boxShadow: 'none',
            background: 'transparent'
          },
          '&.active': {
            background: theme.colors.white,
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
            '&:hover': {
              background: theme.colors.white,
              boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
            }
          }
        }
      })
    }
  }
};
