export const badge = {
  bg: 'defaultBorder',
  color: 'textMedium',
  px: 'xxsm',
  py: 'xxs',
  fontSize: 'xxs',
  lineHeight: 'small',
  fontWeight: 'bold',
  borderRadius: 'xsm',
  css: {
    height: 'auto',
    textTransform: 'uppercase'
  },
  variants: {
    smallLight: {
      borderRadius: 'xs',
      color: 'primary',
      bg: 'white',
      py: 'xxs',
      px: 'xs',
      css: {
        fontSize: '10px',
        textTransform: 'uppercase',
        height: 'auto',
        lineHeight: '15px'
      }
    },
    smallDark: {
      borderRadius: 'xs',
      color: 'white',
      bg: 'black',
      py: 'xxs',
      px: 'xs',
      css: {
        fontSize: '10px',
        textTransform: 'uppercase',
        height: 'auto',
        lineHeight: '15px'
      }
    }
  }
};
