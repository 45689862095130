export const input = {
  border: 'bold',
  borderRadius: 'xs',
  fontSize: 'sm',
  css: ({ invalid, theme }) => ({
    borderColor: invalid ? theme.colors.error : theme.colors.defaultBorder,
    paddingTop: '9px',
    paddingBottom: '7px',
    outline: 'none',
    WebkitAppearance: 'none',
    '&::placeholder': {
      /* modern browser */
      color: theme.colors.textLight,
      fontSize: theme.fontSizes.sm,
      lineHeight: 1.3,
      opacity: 1
    },
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
      boxShadow: '0 0 0 30px white inset !important'
    }
  }),
  variants: {
    title: {
      pl: 'none',
      flex: 1,
      fontSize: 'lg',
      css: ({ invalid, theme }) => ({
        appearance: 'none',
        border: invalid ? theme.borders.regular : 'none',
        borderColor: invalid ? theme.colors.error : theme.colors.defaultBorder,
        backgroundColor: 'transparent',
        width: 'auto',
        '&::placeholder': {
          /* modern browser */
          color: theme.colors.textLight,
          fontSize: theme.fontSizes.lg,
          lineHeight: 1.3
        }
      })
    },
    dialogInput: {
      flex: 1,
      fontSize: 'md',
      css: ({ invalid, theme }) => ({
        borderColor: invalid ? theme.colors.error : theme.colors.defaultBorder,
        border: invalid ? theme.borders.regular : 'none',
        appearance: 'none',
        backgroundColor: 'rgba(55, 60, 79, 0.05)',
        '&::placeholder': {
          /* modern browser */
          color: theme.colors.textLight,
          fontSize: theme.fontSizes.md,
          lineHeight: 1.3
        },
        '&:disabled': {
          color: theme.colors.baseGrey
        },
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
          boxShadow: '0 0 0 30px rgba(55, 60, 79, 0.05) inset !important'
        }
      })
    }
  }
};
