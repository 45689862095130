import { createTheme } from '@deity/falcon-ui';
import { icons } from './icons';
import { root } from './falconComponents/root';
import { appLayout } from './falconComponents/appLayout';
import { gridLayout } from './falconComponents/gridLayout';
import { icon } from './falconComponents/icon';
import { menu } from './falconComponents/menu';
import { menuItem } from './falconComponents/menuItem';
import { link } from './falconComponents/link';
import { list } from './falconComponents/list';
import { listItem } from './falconComponents/listItem';
import { text } from './falconComponents/text';
import { navbar } from './falconComponents/navbar';
import { dropdown } from './falconComponents/dropdown';
import { dropdownMenu } from './falconComponents/dropdownMenu';
import { sidebar } from './falconComponents/sidebar';
import { badge } from './falconComponents/badge';
import { backdrop } from './falconComponents/backdrop';
import { button } from './falconComponents/button';
import { input } from './falconComponents/input';
import { formFieldLayout } from './falconComponents/formFieldLayout';
import { select } from './falconComponents/select';
import { checkbox } from './falconComponents/checkbox';
import { fonts } from './falconComponents/fonts';
import { label } from './falconComponents/label';
import { h1, h2, h3, h4 } from './falconComponents/headings';
import { divider } from './falconComponents/divider';
import { table } from './falconComponents/table';
import { group } from './falconComponents/group';
import { styledBoxVariants } from './components/StyledBox';
import { switchVariants } from './components/SwitchStyling';
import { toastifyCss } from './pluginStyles/toastify';
import { flatpickrCss } from './pluginStyles/flatpickr';
import { stripeCss } from './pluginStyles/stripe';
import { reactCropCss } from './pluginStyles/reactCrop';
import { selectCss } from './pluginStyles/select';
import { highlighterCss } from './pluginStyles/highlighter';
import { numberInputCss } from './pluginStyles/numberInput';
import { cronBuilderCss } from './pluginStyles/cronBuilder';

export const pushProTheme = createTheme({
  colors: {
    primary: '#FF4C40',
    primaryLight: '#FC674E',
    primaryDark: '#292E3A',
    primaryText: '#373C4F',
    secondary: '#f4f4f4',
    secondaryLight: '#F6F6F6',
    secondaryDark: '#F0F0F0',
    secondaryText: '#FFFFFF',
    black: '#303030',
    white: '#FFFFFF',
    textLight: '#B9BAC4',
    textGrey: '#878994',
    textGreyDark: '#32373d',
    textMedium: '#6E7181',
    inputTextBg: '#f5f5f6',
    inputTextIcon: '#bcbdc3',
    contextBg: '#e6e6e6',
    contextText: '#9f9f9f',
    defaultBorder: '#E6E7E9',
    borderGrey: '#C4C4C4',
    borderDark: '#40444f',
    tableTopHeader: '#292E3A',
    preTrialHeader: '#181B23',
    textDark: '#292E3A',
    greyDark: '#e0e3e6',
    grey: '#525252',
    greyLight: '#72757A',
    baseGrey: '#ADB0B2',
    transparent: 'transparent',
    transparentWhite: 'rgba(255,255,255,0.5)',
    transparentBlack: 'rgba(0,0,0,0.1)',
    bodyBg: '#1f2127',
    error: '#FF4C40',
    warning: '#FF8744',
    errorText: '#ffffff',
    errorBg: '#dd0000',
    success: '#0EC266',
    decisionBlock: 'rgba(55, 60, 79, 0.05)',
    listBorder: 'rgba(55, 60, 79, 0.05)'
  },
  icons,
  breakpoints: {
    xs: 320,
    sm: 640,
    headerMessage: 768,
    md: 860,
    lg: 1280,
    xl: 1920
  },
  spacing: {
    none: 0,
    auto: 'auto',
    xxxs: 2,
    xxs: 4,
    xxsm: 6,
    xs: 8,
    tableXSmall: 10,
    xsm: 12,
    tableSmall: 14,
    sm: 16,
    xmd: 20,
    tableMiddle: 20,
    md: 24,
    buttonNormal: 24,
    iconMD: 25,
    lg: 32,
    iconLG: 35,
    xl: 42,
    iconXL: 45,
    priceItemTop: 48,
    xxl: 50,
    xxxl: 64,
    table: 80,
    footer: 200,
    iconPadding: 6,
    iconGap: 100,
    iconXXL: 160,
    negative: -88,
    negativeMinimun: -2,
    negativeSmall: -18,
    preTrialHeader: 52,
    headerSize: 64,
    headerSizeSmall: 40
  },
  fonts: {
    mono: '"SF Mono", monospace',
    sans: '"Inter", sans-serif'
  },
  fontSizes: {
    tiny: 9,
    xxs: 10,
    xs: 12,
    sm: 14,
    md: 16,
    introText: 18,
    buttonmd: 18,
    lg: 24,
    planPrice: 28,
    planTitle: 28,
    headersMobile: 28,
    xl: 32,
    xxl: 48,
    priceLarge: 64,
    xxxl: 80
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    semibold: 500,
    bold: 600,
    extraBold: 800
  },
  lineHeights: {
    reset: 0.8,
    small: 1,
    normal: 1.3,
    default: 1.5,
    medium: 1.7,
    large: 2,
    buttonTiny: '28px',
    buttonOutlineTiny: '24px',
    buttonSmall: '32px',
    buttonNormal: '40px',
    buttonOutlineNormal: '36px',
    tableNormal: '',
    tableLarge: '64px'
  },
  letterSpacings: {
    normal: 'normal',
    default: '-0.02em',
    uppercase: '0.125em',
    caps: '0.025em',
    large: '0.16em',
    headings: '-0.04em',
    headingsSecond: '-0.035em',
    headingsSmall: '0.06em'
  },
  borders: {
    light: '0.5px solid',
    regular: '1px solid',
    bold: '2px solid',
    thick: '3px solid',
    semiBold: '6px solid',
    extraBold: '10px solid',
    dotted: '1px dotted',
    dashed: '1px dashed'
  },
  borderRadius: {
    none: 0,
    xxs: 2,
    xsm: 3,
    xs: 4,
    sm: 8,
    md: 16,
    rounded: 28,
    lg: 32,
    xl: 333,
    round: '50%'
  },
  boxShadows: {
    none: 'none',
    top: '0 -5px 10px -5px rgba(0,0,0,0.05)',
    xs: '0 4px 12px rgba(0,0,0,.1)',
    sm: '0 8px 16px rgba(0,0,0,.2)',
    md: '0 5px 30px rgba(0,0,0,.4)',
    lg: '0 0 2px 0 rgba(0,0,0,.08),0 8px 32px 0 rgba(0,0,0,.16)',
    light: '0 2px 4px 0 rgba(0,0,0,0.05)',
    inset: 'inset 0px -2px 4px rgba(0, 0, 0, 0.05)',
    footer: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    card: '0px 4px 16px rgba(156, 0, 0, 0.08)',
    cardSmall: '0px 4px 12px rgba(50, 63, 83, 0.04)'
  },
  keyframes: {
    loader: {
      '0%': {
        transform: 'rotateZ(0)'
      },
      '100%': {
        transform: 'rotateZ(360deg)'
      }
    },
    fadeIn: {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    draw: {
      '100%': {
        strokeDashoffset: 0
      }
    },
    shrink: {
      '0%': {
        backgroundSize: '100%'
      },
      '100%': {
        backgroundSize: '470px auto'
      }
    },
    fadeInBackdrop: {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 0.4
      }
    },
    fadeOutBackdrop: {
      '0%': {
        opacity: 0.4
      },
      '100%': {
        opacity: 0
      }
    },
    autoCompleteInput: {
      '0%': {
        background: 'transparent'
      },
      '100%': {
        background: 'transparent'
      }
    }
  },
  components: {
    // Falcon component style overrides
    appLayout,
    backdrop,
    badge,
    button,
    checkbox,
    divider,
    dropdown,
    dropdownMenu,
    fonts,
    formFieldLayout,
    gridLayout,
    group,
    h1,
    h2,
    h3,
    h4,
    icon,
    input,
    label,
    link,
    list,
    listItem,
    menu,
    menuItem,
    navbar,
    root,
    select,
    sidebar,
    // Variants for custom components
    styledBox: {
      variants: styledBoxVariants
    },
    switch: {
      variants: switchVariants
    },
    table,
    text
  }
});

export const globalCss = {
  body: {
    margin: 0,
    '&.modal-open': {
      height: '100%',
      width: '100%',
      position: 'fixed',
      overflow: 'hidden'
    }
  },
  '.maxWidth': {
    maxWidth: '60%'
  },
  '.intercom-lightweight-app': {
    zIndex: '2147463001!important'
  },
  '.intercom-lightweight-app-launcher': {
    zIndex: '2147463003!important'
  },
  '.hide-input': { display: 'none' },
  '.table-responsive': {
    display: 'block',
    width: '100%',
    overflowX: 'auto',
    backgroundImage:
      'linear-gradient(to right, #F6F6F6, #F6F6F6), linear-gradient(to right, #F6F6F6, #F6F6F6), linear-gradient(to right, rgba(0, 0, 20, .15), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .15), rgba(255, 255, 255, 0))',
    backgroundPosition: 'left center, right center, left center, right center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#F6F6F6',
    backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
    backgroundAttachment: 'local, local, scroll, scroll',
    'table.table': {
      'tbody tr, tbody td': {
        background: 'none'
      }
    },
    '&.bgWhite': {
      backgroundImage:
        'linear-gradient(to right, white, white), linear-gradient(to right, white, white), linear-gradient(to right, rgba(0, 0, 20, .15), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 20, .15), rgba(255, 255, 255, 0))',
      backgroundColor: 'white'
    }
  },
  'table.table': {
    width: '100%',
    maxWidth: '100%'
  },
  [`@media only screen and (min-width: ${pushProTheme.breakpoints.xs}px)`]: {
    'table.table': {
      overflowY: 'unset'
    }
  },
  [`@media only screen and (max-width: ${pushProTheme.breakpoints.sm - 1}px)`]: {
    '#hubspot-messages-iframe-container': { display: 'none !important', visibility: 'hidden' }
  },
  [`@media only screen and (max-width: ${pushProTheme.breakpoints.md - 1}px)`]: {
    '.maxWidth': {
      maxWidth: '100%'
    }
  },
  // Fix for remove jumping when scrollbar appears
  [`@media only screen and (min-width: ${pushProTheme.breakpoints.md}px)`]: {
    html: {
      overflowX: 'hidden',
      marginRight: 'calc(-1 * (100vw - 100%))'
    }
  },
  ...toastifyCss(pushProTheme),
  ...flatpickrCss(pushProTheme),
  ...stripeCss(pushProTheme),
  ...reactCropCss(pushProTheme),
  ...selectCss(pushProTheme),
  ...highlighterCss(pushProTheme),
  ...numberInputCss(pushProTheme),
  ...cronBuilderCss(pushProTheme)
};
