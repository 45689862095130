export const toastifyCss = theme => ({
  '.Toastify__toast': {
    borderRadius: theme.borderRadius.sm,
    padding: 0,
    minHeight: 20,
    color: theme.colors.white,
    backgroundColor: 'transparent'
  },
  '.Toastify__progress-bar': {
    height: 2,
    '&--success': {
      background: theme.colors.success
    },
    '&--error': {
      background: theme.colors.errorBg
    },
    '&--warn': {
      background: theme.colors.warning
    }
  }
});
