import { themed } from '@deity/falcon-ui';

export const StyledBox = themed({
  tag: 'div',
  defaultTheme: {
    styledBox: {}
  }
});

export const styledBoxVariants = {
  gradient: {
    css: ({ theme }) => ({
      color: theme.colors.secondaryText,
      background: `linear-gradient(334.84deg, ${theme.colors.primary} 0%, ${theme.colors.primaryLight} 100%)`,
      '.themed-icon': {
        color: theme.colors.white
      }
    })
  },
  statsLight: {
    boxShadow: 'xs',
    bg: 'white',
    css: ({ theme }) => ({
      transition: 'all 0.3s ease',
      ':hover': {
        boxShadow: theme.boxShadows.sm
      },
      'div.themed': {
        opacity: '1!important'
      },
      '.themed-icon, .themed-text': {
        stroke: theme.colors.textMedium,
        color: theme.colors.textMedium
      },
      '.themed-divider': {
        borderColor: theme.colors.defaultBorder,
        opacity: '1!important'
      },
      color: theme.colors.primaryText
    })
  },
  light: {
    boxShadow: 'cardSmall',
    bg: 'white',
    css: {
      transition: 'all 0.3s ease'
    }
  },
  lightblue: {
    css: {
      color: 'white',
      background: '#329AF0'
    }
  },
  gradientDark: {
    css: ({ theme }) => ({
      color: theme.colors.secondaryText,
      background: `linear-gradient(334.84deg, ${theme.colors.primaryDark} 0%, ${theme.colors.black} 100%)`
    })
  }
};
