import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { LoaderOverlay } from '../Loader/LoaderOverlay';
import { GET_CUSTOMER_TYPE } from '../Customer/CustomerQuery';

export const OnlyUnauthenticatedRoute = ({ component, redirectTo, ...rest }) => {
  const { data, loading } = useQuery(GET_CUSTOMER_TYPE);

  if (loading) {
    return <LoaderOverlay />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        data?.customer ? (
          <Redirect
            to={{
              pathname: redirectTo
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
};
OnlyUnauthenticatedRoute.defaultProps = {
  redirectTo: '/'
};
OnlyUnauthenticatedRoute.propTypes = {
  ...Route.propTypes,
  redirectTo: PropTypes.string
};
