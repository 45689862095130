import App from './src/App';
import clientApolloSchema from './src/clientState';

// Remove this if statement when the new version of node-fetch is implemented in falcon-client
// https://github.com/node-fetch/node-fetch
if (typeof window === 'undefined') {
  global.AbortController = undefined;
}

export { App as default, clientApolloSchema };
