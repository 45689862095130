export const gridLayout = {
  css: ({ theme }) => ({
    '&.dialogInput': {
      background: 'none',
      border: 'none',
      color: theme.colors.primaryDark,
      input: {
        backgroundColor: theme.colors.inputTextBg,
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        '&.noRightBorderRadius': {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0'
        }
      },
      '.inputContext': {
        backgroundColor: theme.colors.contextBg,
        borderRadius: theme.borderRadius.xs,
        fontFamily: 'Inter !important',
        justifyContent: 'center',
        padding: theme.spacing.xs,
        ':after': {
          display: 'none'
        },
        p: {
          fontSize: theme.fontSizes.xs,
          fontWeight: theme.fontWeights.extraBold,
          textTransform: 'uppercase',
          color: theme.colors.contextText
        },
        '&.small': {
          p: {
            fontSize: theme.fontSizes.sm,
            fontWeight: theme.fontWeights.regular,
            textTransform: 'none'
          }
        },
        '&:first-of-type': {
          borderTopRightRadius: '0',
          borderBottomRightRadius: '0'
        },
        '&:nth-of-type(2)': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0'
        }
      },
      '&.connected': {
        'div:last-of-type': {
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0'
        },
        '.react-select__control': {
          backgroundColor: theme.colors.inputTextBg,
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          color: theme.colors.primaryDark
        },
        '.reactSelect.noRightBorderRadius': {
          '.react-select__control': {
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0'
          }
        }
      },
      '.reactSelect': {
        '.react-select__control': {
          backgroundColor: theme.colors.inputTextBg,
          color: theme.colors.primaryDark,
          border: 'none'
        },
        '&.operator': {
          '.react-select__control': {
            borderRadius: theme.borderRadius.xs,
            border: 'none'
          }
        }
      }
    }
  }),
  variants: {
    splitView: {
      gridTemplateColumns: '1fr 1fr',
      gridRowGap: 'xs',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  }
};
