import React from 'react';
import { FlexLayout, Text } from '@deity/falcon-ui';
import { Icon } from '../components/Icon/Icon';
import { displayInfinite } from './displayInfinite';

export const features = [
  {
    type: 'messages',
    title: 'Push Notifications',
    plans: [
      {
        name: 'pretrial',
        amount: '3',
        display: '3 messages'
      },
      {
        name: 'free',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'basic',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'basic_v2',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'bronze',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'silver',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'business',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'business_v2',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'gold',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      },
      {
        name: 'unlimited',
        amount: 'Unlimited',
        display: displayInfinite('messages')
      }
    ]
  },
  {
    highlight: true,
    type: 'subscribers',
    title: 'Subscribers',
    plans: [
      {
        name: 'pretrial',
        amount: '100',
        display: '100 subscribers'
      },
      {
        name: 'free',
        amount: '2,000',
        display: '2,000 subscribers'
      },
      {
        name: 'basic',
        amount: '2,500',
        display: '2,500 subscribers'
      },
      {
        name: 'basic_v2',
        amount: '2,500',
        display: '2,500 subscribers'
      },
      {
        name: 'bronze',
        amount: '6,000',
        display: '6,000 subscribers'
      },
      {
        name: 'silver',
        amount: '18,000',
        display: '18,000 subscribers'
      },
      {
        name: 'business',
        amount: '25,000',
        display: '25,000 subscribers'
      },
      {
        name: 'business_v2',
        amount: '25,000',
        display: '25,000 subscribers'
      },
      {
        name: 'gold',
        amount: '54,000',
        display: '54,000 subscribers'
      },
      {
        name: 'unlimited',
        amount: 'Unlimited',
        display: displayInfinite('subscribers')
      }
    ]
  },
  {
    highlight: true,
    type: 'domains',
    title: 'Domains',
    plans: [
      {
        name: 'pretrial',
        amount: '1',
        display: '1 domain'
      },
      {
        name: 'free',
        amount: '1',
        display: '1 domain'
      },
      {
        name: 'basic',
        amount: '2',
        display: '2 domains'
      },
      {
        name: 'basic_v2',
        amount: '2',
        display: '2 domains'
      },
      {
        name: 'bronze',
        amount: '3',
        display: '3 domains'
      },
      {
        name: 'silver',
        amount: '7',
        display: '7 domains'
      },
      {
        name: 'business',
        amount: '10',
        display: '10 domains'
      },
      {
        name: 'business_v2',
        amount: '10',
        display: '10 domains'
      },
      {
        name: 'gold',
        amount: '15',
        display: '15 domains'
      },
      {
        name: 'unlimited',
        amount: '1000',
        display: displayInfinite('domains')
      }
    ]
  },
  {
    highlight: true,
    tableFeature: false,
    type: 'branding',
    title: 'Custom Branding',
    plans: [
      {
        name: 'free',
        display: <i>* PushPro Branding Only</i>
      },
      {
        name: 'bronze',
        display: 'Custom Branding'
      }
    ]
  },
  {
    highlight: true,
    type: 'multi-tenant',
    title: 'Multi user support',
    plans: [
      {
        name: 'basic',
        amount: '0',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: '0',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: '3',
        display: '3 users'
      },
      {
        name: 'business',
        amount: '5',
        display: '5 users'
      },
      {
        name: 'business_v2',
        amount: '5',
        display: '5 users'
      },
      {
        name: 'gold',
        amount: '5',
        display: '5 users'
      },
      {
        name: 'unlimited',
        amount: '15',
        display: '15 users'
      }
    ]
  },
  {
    highlight: true,
    tableFeature: false,
    type: 'segmentation',
    title: 'Segmentation',
    plans: [
      {
        name: 'silver',
        display: 'Segmentation'
      },
      {
        name: 'gold',
        display: 'Segmentation'
      },
      {
        name: 'unlimited',
        display: 'Segmentation'
      }
    ]
  },
  {
    type: 'mobile_desktop',
    title: 'Mobile + Desktop Notifications',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'reports',
    title: 'Reports & Analytics',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'gdpr',
    title: 'GDPR Compliant',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'emoji',
    title: 'Emojis 😋',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'characters',
    title: 'Special Characters',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'browsers',
    title: 'Full Browser Support',
    plans: [
      {
        name: 'pretrial',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'unlimited_branding',
    title: 'Custom Branding',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'unlimited_optin',
    title: 'Custom Opt-in Prompts',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'scheduled_sending',
    title: 'Scheduled Sending',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'segmentation',
    title: 'Audience Segmentation',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <Text fontSize="xs" fontWeight="semibold" color="success" style={{ whiteSpace: 'pre-line' }}>
              <Icon src="check" color="success" />
            </Text>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'rich_media',
    title: 'Rich Media Notifications (coming soon)',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'analytics',
    title: 'Google Analytics (coming soon)',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'event_triggered',
    title: 'Event Triggered Notifications',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'geo',
    title: 'Geo Targeting (coming soon)',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'api',
    title: 'API Access (coming soon)',
    plans: [
      {
        name: 'pretrial',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'free',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'basic_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'bronze',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'silver',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'business_v2',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'gold',
        amount: 'no',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="cross" color="textLight" />
            </FlexLayout>
          </>
        )
      },
      {
        name: 'unlimited',
        amount: 'yes',
        display: (
          <>
            <FlexLayout alignItems="center" justifyContent="center">
              <Icon src="check" color="success" />
            </FlexLayout>
          </>
        )
      }
    ]
  },
  {
    type: 'support',
    title: 'Support',
    plans: [
      {
        name: 'free',
        amount: 'mail',
        display: '30 days Mail Only'
      },
      {
        name: 'basic',
        amount: 'mail',
        display: 'Mail only'
      },
      {
        name: 'basic_v2',
        amount: 'mail',
        display: 'Mail only'
      },
      {
        name: 'bronze',
        amount: 'mail',
        display: 'Mail only'
      },
      {
        name: 'silver',
        amount: 'mail',
        display: 'Mail only'
      },
      {
        name: 'business',
        amount: 'mail',
        display: 'Mail + Private Queue'
      },
      {
        name: 'business_v2',
        amount: 'mail',
        display: 'Mail + Private Queue'
      },
      {
        name: 'gold',
        amount: 'mail',
        display: 'Mail + Private Queue'
      },
      {
        name: 'unlimited',
        amount: 'mail',
        display: (
          <>
            Code level support <br /> + Private Queue
          </>
        )
      }
    ]
  }
];
