export const sidebar = {
  px: 'sm',
  pt: 'sm',
  boxShadow: 'subtle',
  css: {
    boxSizing: 'border-box',
    width: {
      xs: '80vw',
      sm: 510
    }
  }
};
