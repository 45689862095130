export const stripeCss = theme => ({
  '.StripeElement': {
    color: theme.colors.textDark,
    display: 'block',
    padding: `12px ${theme.spacing.sm}px 7px ${theme.spacing.sm}px`,
    fontSize: '1.15em',
    border: 0,
    outline: 0,
    height: '40px',
    borderRadius: theme.borderRadius.xs,
    backgroundColor: 'rgba(55, 60, 79, 0.05)',
    '&::placeholder': {
      /* modern browser */
      color: theme.colors.textLight,
      fontSize: theme.fontSizes.md,
      lineHeight: 1.3
    }
  }
});
