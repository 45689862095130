export const button = {
  px: 'tableMiddle',
  py: 'xsm',
  lineHeight: 'small',
  fontSize: 'md',
  borderRadius: 'xs',
  fontWeight: 'bold',
  border: 'none',
  whiteSpace: 'nowrap',
  breakWord: 'keep-all',
  css: ({ theme }) => ({
    '.themed-icon': {
      stroke: theme.colors.secondaryText
    },
    '.themed-text': {
      fontWeight: theme.fontWeights.regular,
      fontSize: theme.fontSizes.sm
    },
    outline: 'none',
    height: 'auto',
    background: `linear-gradient(334.84deg, ${theme.colors.primary} 0%, ${theme.colors.primaryLight} 100%)`,
    transition: 'all 0.5s ease',
    color: theme.colors.secondaryText,
    ':disabled': {
      opacity: 0.6,
      cursor: 'not-allowed'
    },
    '&:hover:enabled': {
      boxShadow: theme.boxShadows.xs
    }
  }),

  variants: {
    loader: {
      size: 'xl',
      borderRadius: 'round',
      border: 'bold',
      borderColor: 'primary',
      p: 'none',
      css: props => ({
        animation: `${props.theme.keyframes.loader} .8s linear infinite`,
        borderRightColor: props.theme.colors.white,
        background: 'none',
        fontSize: 0,
        whiteSpace: 'nowrap',
        cursor: 'default',
        overflow: 'hidden',
        ':hover': {
          borderColor: props.theme.colors.primaryLight,
          borderRightColor: props.theme.colors.white
        }
      })
    },
    linkButton: {
      css: ({ theme }) => ({
        border: '0',
        color: theme.colors.textDark,
        background: 'none',
        '&:hover:enabled': {
          background: 'none',
          color: theme.colors.textDark,
          boxShadow: 'none'
        }
      })
    },
    outline: {
      borderColor: 'primary',
      border: 'regular',
      color: 'primaryLight',
      pl: 'sm',
      pr: 'md',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.primary,
          width: '20px',
          height: '20px'
        },
        background: 'none',
        color: theme.colors.primary,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          color: theme.colors.white,
          background: `linear-gradient(334.84deg, ${theme.colors.primary} 0%, ${theme.colors.primaryLight} 100%)`
        }
      })
    },
    stepModal: {
      border: 'regular',
      color: 'textDark',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: '#ADB0B3',
          width: '20px',
          height: '20px'
        },
        background: 'none',
        borderColor: '#ADB0B3',
        color: 'theme.colors.textDark',
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          color: theme.colors.white,
          background: theme.colors.textDark,
          borderColor: theme.colors.textDark
        }
      })
    },
    outlineGrey: {
      borderColor: 'textLight',
      border: 'regular',
      color: 'textDark',
      pl: 'sm',
      pr: 'md',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.textDark,
          width: '20px',
          height: '20px'
        },
        background: 'white',
        color: theme.colors.textDark,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          color: theme.colors.white,
          background: theme.colors.textDark,
          borderColor: theme.colors.textDark
        }
      })
    },
    outlineSecondary: {
      borderColor: 'primaryDark',
      color: 'primaryDark',
      pl: 'sm',
      pr: 'md',
      css: ({ theme }) => ({
        border: '1px solid',
        '.themed-icon': {
          stroke: theme.colors.primaryDark,
          width: '20px',
          height: '20px'
        },
        background: 'none',
        color: theme.colors.primaryDark,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          color: theme.colors.white,
          background: theme.colors.primaryDark
        }
      })
    },
    outlineWhite: {
      borderColor: 'white',
      border: 'bold',
      color: 'white',
      pl: 'sm',
      pr: 'md',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.white,
          width: '20px',
          height: '20px'
        },
        background: 'none',
        color: theme.colors.white,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.primaryText
          },
          color: theme.colors.primaryText,
          background: theme.colors.white
        }
      })
    },
    outlineError: {
      borderColor: 'error',
      border: 'regular',
      color: 'error',
      pl: 'sm',
      pr: 'md',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.error,
          width: '20px',
          height: '20px'
        },
        background: 'none',
        color: theme.colors.error,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          color: theme.colors.white,
          background: theme.colors.error
        }
      })
    },
    white: {
      bg: 'white',
      border: 'none',
      color: 'textDark',
      boxShadow: 'none',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.textDark
        },
        background: theme.colors.white,
        color: theme.colors.textDark,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.textDark
          },
          background: theme.colors.white
        }
      })
    },
    dark: {
      css: ({ theme }) => ({
        background: theme.colors.primaryDark,
        color: theme.colors.white,
        '.themed-icon': {
          stroke: theme.colors.white
        },
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.white
          },
          background: theme.colors.primaryDark
        }
      })
    },
    grey: {
      color: 'greyDark',
      bg: 'defaultBorder',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.primaryText
        },
        background: theme.colors.defaultBorder,
        color: theme.colors.primaryText,
        '&:hover:enabled': {
          color: theme.colors.primaryText,
          background: theme.colors.defaultBorder
        }
      })
    },
    rounded: {
      borderRadius: 'rounded',
      fontSize: 'buttonmd',
      css: ({ theme }) => ({
        background: `linear-gradient(334.84deg, ${theme.colors.primary} 0%, ${theme.colors.primaryLight} 100%)`
      })
    },
    roundedWhite: {
      borderRadius: 'rounded',
      fontSize: 'buttonmd',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.primaryText
        },
        background: theme.colors.secondaryText,
        color: theme.colors.primaryText,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.secondaryText
          },
          color: theme.colors.secondaryText
        }
      })
    },
    roundedGrey: {
      borderRadius: 'rounded',
      fontSize: 'buttonmd',
      width: 'auto',
      p: 'xs',
      css: ({ theme }) => ({
        '.themed-icon': {
          stroke: theme.colors.primaryText
        },
        background: theme.colors.defaultBorder,
        color: theme.colors.primaryText,
        '&:hover:enabled': {
          '.themed-icon': {
            stroke: theme.colors.secondaryText
          },
          color: theme.colors.secondaryText
        }
      })
    },
    primarySmall: {
      py: 'xs',
      px: 'sm',
      fontSize: 'xs'
    },
    link: {
      boxShadow: 'none',
      css: ({ theme }) => ({
        borderRadius: 0,
        boxShadow: 'none',
        fontSize: theme.fontSizes.md,
        transition: 'none',
        color: theme.colors.primaryText,
        background: 'none',
        padding: 0,
        '&:active': {
          transform: 'none'
        },
        '&:hover:enabled': {
          background: 'none',
          boxShadow: 'none'
        },
        '.themed-icon': {
          stroke: 'currentColor'
        }
      })
    }
  }
};
