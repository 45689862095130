import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import loadable from '../../components/loadable';
import { OnlyUnauthenticatedRoute, ProtectedRoute } from '../../components/Routes';

export const PublicAppRoutes = () => {
  const Login = loadable(() => import(/* webpackChunkName: "public/auth/login" */ './Auth/Login'));
  const Callback = loadable(() => import(/* webpackChunkName: "public/auth/callback" */ './Auth/Callback'));
  const Logout = loadable(() => import(/* webpackChunkName: "public/auth/logout" */ './Auth/Logout'));
  const InviteValidate = loadable(() => import(/* webpackChunkName: "public/invite/validate" */ './Invite/Validate'));
  const CheckoutThanks = loadable(() => import(/* webpackChunkName: "public/checkout/thanks" */ './CheckoutThanks'));
  const Onboarding = loadable(() => import(/* webpackChunkName: "public/onboarding" */ './Onboarding/Onboarding'));
  const PreLogin = loadable(() =>
    import(/* webpackChunkName: "public/prelogin" */ '../../components/Layouts/PreLogin')
  );
  const Portal = loadable(() => import(/* webpackChunkName: "portal/portal" */ '../../pages/portal/Portal'));

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <OnlyUnauthenticatedRoute exact path="/login" component={Login} redirectTo="/onboarding" />
      <Route exact path="/callback" component={Callback} />
      <Route path="/invite/validate/:inviteUuid" component={InviteValidate} />
      <Route exact path="/logout" component={Logout} />
      <ProtectedRoute path="/portal" component={Portal} redirectTo="/login" />

      {/* Subscription takes care of authentication checking itself */}
      <Route path="/onboarding" component={Onboarding} />
      <Route path="/prelogin" component={PreLogin} />
      <Route exact path="/checkout/thanks" component={CheckoutThanks} />
    </Switch>
  );
};
