export const menuItem = {
  fontSize: 'md',
  fontWeight: 'semibold',
  px: 'sm',
  py: 'md',
  color: 'secondaryText',
  display: 'flex',
  alignItems: 'center',
  css: ({ theme }) => ({
    transition: 'background .3s ease',
    cursor: 'pointer',
    userSelect: 'none',
    listStyle: 'none',
    borderBottom: theme.borders.regular,
    borderColor: theme.colors.borderDark,
    '&.subMenuItem': {
      paddingTop: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
      borderColor: theme.colors.tableTopHeader,
      paddingLeft: theme.spacing.xxl,
      color: theme.colors.textLight,
      fontWeight: theme.fontWeights.regular,
      fontSize: theme.fontSizes.sm,
      '&.active': {
        color: theme.colors.primary
      },
      ':hover': {
        borderColor: theme.colors.borderDark
      }
    },
    ':not(:last-child)': {
      borderColor: theme.colors.borderDark
    },
    ':hover': {
      background: theme.colors.tableTopHeader
    }
  })
};
