import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { ClientConfigQuery } from '@deity/falcon-front-kit';
import { ThemeProvider } from '@deity/falcon-ui';
import { ServiceWorkerRegistrar } from '@deity/falcon-service-worker';
import { ThemeContext } from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { pushProTheme, globalCss } from './theme/theme';
import { ErrorBoundary } from './components/Errors/ErrorBoundary';
import logo from './assets/logo.png';
import { init } from './service/getStripeInfo';
import { ToastContainer } from './components/UserFeedback/ToastContainer';
import { LocaleProvider } from './components/Locale/LocaleProvider';
import { PublicAppRoutes } from './pages/public/PublicAppRoutes';
import { Auth0Provider } from './components/Auth/AuthContext';
import { HubspotChat } from './components/Hubspot/HubspotChat';

const stripePromise = key => loadStripe(key);

const InitStripeInfo = ({ children }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  const StripeInit = useMemo(
    () => (
      <ClientConfigQuery variables={{ key: 'stripe' }}>
        {({ data: { clientConfig: stripe } }) => {
          // set mode for stripe configuration
          init(stripe.mode);
          return <Elements stripe={stripePromise(stripe.key)}>{children}</Elements>;
        }}
      </ClientConfigQuery>
    ),
    []
  );

  return StripeInit;
};

const HeadMetaTags = () => (
  <ClientConfigQuery variables={{ key: 'intercom' }}>
    {({ data: { clientConfig: config } }) => (
      <Helmet defaultTitle="PushPro" titleTemplate="%s | Empower push notifications" htmlAttributes={{ lang: 'en' }}>
        <meta name="description" content="Unleash the power of direct customer engagement by push notifications." />
        <meta name="keywords" content="pwa,push,engagement,web,shop,qlab" />
        <meta name="theme-color" content="#EF4854" />
        <meta name="format-detection" content="telephone=yes" />
        <meta property="og:title" content="PushPro" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Unleash the power of direct customer engagement by push notifications."
        />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={logo} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />
        <link rel="shortcut icon" href="/favicon.png" />
        <link
          href="https://fonts.googleapis.com/css?family=Inter:100,300,400,500,600,800&display=swap"
          rel="stylesheet"
        />
        {/* Start Intercom tracking code */}
        <script>
          {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${config.applicationId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
        </script>
        {/* Start Hubspot tracking code */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/4378318.js" />
      </Helmet>
    )}
  </ClientConfigQuery>
);

const App = () => (
  <ServiceWorkerRegistrar>
    <LocaleProvider>
      <ThemeContext.Provider value={pushProTheme}>
        <ThemeProvider theme={pushProTheme} globalCss={globalCss}>
          <ToastContainer />
          <HeadMetaTags />
          <HubspotChat />
          <ErrorBoundary>
            <ClientConfigQuery variables={{ key: 'auth' }}>
              {({ data: { clientConfig: config } }) => (
                <Auth0Provider config={config}>
                  <InitStripeInfo>
                    <PublicAppRoutes />
                  </InitStripeInfo>
                </Auth0Provider>
              )}
            </ClientConfigQuery>
          </ErrorBoundary>
        </ThemeProvider>
      </ThemeContext.Provider>
    </LocaleProvider>
  </ServiceWorkerRegistrar>
);

export default App;
