import { Box, Text } from '@deity/falcon-ui';
import React from 'react';

export const plans = {
  pretrial: {
    name: 'Pre-trial',
    id: 'plan_H5t5Xa97pgI0Cm'
  },
  free: {
    name: 'Free',
    id: 'plan_ElL4SJaod4zDlG'
  },
  basic: {
    name: 'Basic',
    description: (
      <Box mb="md">
        <Text className="description" flex={1} fontSize="sm" color="textGrey">
          Engage with all your website visitors with web push notifications. Add your own brand and style, retain users
          and drive conversion.
        </Text>
        <Text fontWeight="semibold" fontSize="sm" color="textGrey" style={{ display: 'inline' }}>
          Perfect for non-profit or small local businesses to get started with PushPro.
        </Text>
      </Box>
    ),
    month: {
      ids: ['plan_H5szutNlnKa6CB', 'price_1HbKd4I0jsznKlju2zNadl4O'],
      current: {
        id: 'price_1HbKd4I0jsznKlju2zNadl4O',
        price: '15.00'
      }
    },
    year: {
      ids: ['plan_H5t04wgEpZh9k6', 'price_1HbKidI0jsznKljuazl00DhC'],
      current: {
        id: 'price_1HbKidI0jsznKljuazl00DhC',
        price: '90.00'
      }
    }
  },
  bronze: {
    name: 'Starting',
    description: '',
    month: {
      ids: ['plan_Ep6iYeMHDdJdaz'],
      price: '23.99'
    },
    year: {
      ids: ['plan_Ep6q6KOmyFzqOS'],
      price: '17.99'
    }
  },
  silver: {
    name: 'Growing',
    description: '',
    month: {
      ids: ['plan_Ep7PBh55FEq8sG'],
      price: '39.99'
    },
    year: {
      ids: ['plan_Ep7RGcXCpTVVrn'],
      price: '29.99'
    }
  },
  business: {
    name: 'Business',
    description: (
      <Box mb="md">
        <Text className="description" flex={1} fontSize="sm" color="textGrey">
          Target specific groups of people and see your click-through rates reach as high as 20%.
        </Text>
        <Text fontWeight="semibold" fontSize="sm" color="textGrey" style={{ display: 'inline' }}>
          Perfect for any online business that wants to accelerate growth.
        </Text>
      </Box>
    ),
    month: {
      ids: ['plan_H5t15Msy35eYOa', 'price_1HbKeYI0jsznKljuXCnwfxYe'],
      current: {
        id: 'price_1HbKeYI0jsznKljuXCnwfxYe',
        price: '85.00'
      }
    },
    year: {
      ids: ['plan_H5t1hFAkCPPMNO', 'price_1HbKsuI0jsznKljuZdy441AW'],
      current: {
        id: 'price_1HbKsuI0jsznKljuZdy441AW',
        price: '500.00'
      }
    }
  },
  gold: {
    name: 'Booming',
    description: '',
    month: {
      ids: ['plan_Ep7YRNl0DyuDOx'],
      price: '58.65'
    },
    year: {
      ids: ['plan_Ep7ZIwroce6JE6'],
      price: '43.99'
    }
  },
  unlimited: {
    name: 'Enterprise',
    description: (
      <Text className="description" mb="md" flex={1} fontSize="sm" color="textGrey">
        Gain access to an unlimited amount of subscribers and domains to drastically improve your marketing performance.
        Get quick feedback with our priority support.
      </Text>
    ),
    month: {
      ids: ['plan_H8c6xwPn8EzThJ', 'price_1IEbeyI0jsznKljuyEwdpKAY'],
      current: {
        id: 'price_1IEbeyI0jsznKljuyEwdpKAY',
        price: '145.50'
      }
    },
    year: {
      ids: ['price_1IEuQFI0jsznKljuq7q3kxP4'],
      current: {
        id: 'price_1IEuQFI0jsznKljuq7q3kxP4',
        price: '0.00'
      }
    }
  }
};
