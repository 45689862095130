import React, { createContext, useContext } from 'react';
import { WebAuth } from 'auth0-js';

const generateAuth = config =>
  new WebAuth({
    domain: config.domain,
    clientID: config.clientId,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token',
    scope: 'openid id profile email given_name family_name identities read:current_user'
  });

const Auth0Context = createContext(null);

const useContextValue = config => {
  return {
    auth0: generateAuth(config)
  };
};

export const Auth0Provider = ({ children, config }) => {
  const value = useContextValue(config);
  return <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>;
};

export const useAuth0Context = () => {
  return useContext(Auth0Context);
};
