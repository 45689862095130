export const flatpickrCss = theme => ({
  '.flatpickr-calendar': {
    fontFamily: theme.fonts.sans
  },
  '.flatpickr-disabled': {
    color: 'rgba(72, 72, 72, 0.3)'
  },
  '.flatpickr-value': {
    border: 'none',
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fonts.sans,
    margin: `0 0 ${theme.spacing.sm}px ${theme.spacing.xs}px`
  },
  '.flatpickr-input': {
    fontFamily: theme.fonts.sans,
    borderRadius: theme.borderRadius.xs,
    border: theme.borders.bold,
    fontSize: theme.fontSizes.sm,
    borderColor: theme.colors.defaultBorder,
    paddingTop: '9px',
    paddingBottom: '7px',
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    '&:focus': {
      outline: 'none',
      boxShadow: 'none'
    },
    '&::placeholder': {
      /* modern browser */
      color: theme.colors.textLight,
      fontSize: theme.fontSizes.sm,
      lineHeight: 1
    },
    '&.hidden': {
      background: 'none',
      border: 'none'
    }
  }
});
