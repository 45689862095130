import React from 'react';
import { ToastContainer as ToastifyCountainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContainer = () => (
  <ToastifyCountainer
    autoClose={5000}
    draggable={false}
    position="top-right"
    transition={Slide}
    // zindex has to be this high because of the modal and intercom button's zindex
    style={{ marginTop: '100px', zIndex: 3147483000, position: 'fixed' }}
  />
);
