import React from 'react';
import { FlexLayout } from '@deity/falcon-ui';
import { Icon } from '../components/Icon/Icon';

export const displayInfinite = text => (
  <>
    <FlexLayout alignItems="center" justifyContent="center">
      <Icon src="infinite" color="inherit" style={{ fill: 'currentColor' }} mr="xs" size="md" /> {text}
    </FlexLayout>
  </>
);
