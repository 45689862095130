import { themed } from '@deity/falcon-ui';

export const SwitchStyling = themed({
  tag: 'span',
  defaultTheme: {
    switch: {
      css: ({ theme }) => ({
        '.switch': {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'middle',
          marginLeft: theme.spacing.xs,
          marginRight: theme.spacing.xs,
          width: '40px',
          height: '24px',
          boxShadow: 'none'
        },
        input: {
          opacity: 0,
          cursor: 'pointer',
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          padding: 0,
          margin: 0,
          zIndex: 100
        },
        '.label': {
          opacity: 0.3,
          textTransform: 'uppercase',
          fontSize: theme.fontSizes.xxs,
          color: theme.colors.primaryText,
          webkitTransition: '.4s',
          transition: '.4s'
        },
        '&[data-checked="true"] .labelOn , &[data-checked="false"] .labelOff': {
          opacity: 1,
          webkitTransition: '.4s',
          transition: '.4s'
        },
        '&[data-checked="true"] .slider': {
          background: theme.colors.success,
          opacity: 1
        },
        '&[data-checked="true"] .slider:before': {
          webkitTransform: 'translateX(15px)',
          msTransform: 'translateX(15px)',
          transform: 'translateX(15px)'
        },
        '.slider': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(7, 8, 13, 0.15)',
          webkitTransition: '.4s',
          transition: '.4s',
          boxShadow: 'inset 0px 2px 1px rgba(0, 0, 0, 0.1)',
          '&:before': {
            position: 'absolute',
            content: '""',
            height: '18px',
            width: '18px',
            left: '4px',
            bottom: '3px',
            backgroundColor: 'white',
            webkitTransition: '.4s',
            transition: '.4s',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)'
          },
          '&.round': {
            borderRadius: '150px',
            '&:before': {
              borderRadius: '50%'
            }
          }
        }
      })
    }
  }
});

export const switchVariants = {
  small: {
    css: ({ theme }) => ({
      '.switch': {
        position: 'relative',
        margin: 'none',
        width: '28px',
        height: '16px'
      },
      '&[data-checked="true"] .slider': {
        backgroundColor: theme.colors.success
      },
      '&[data-checked="true"] .slider:before': {
        webkitTransform: 'translateX(11px)',
        msTransform: 'translateX(11px)',
        transform: 'translateX(11px)'
      },
      '.slider': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        webkitTransition: '.4s',
        transition: '.4s',
        borderRadius: '12px',
        backgroundColor: 'rgba(7, 8, 13, 0.15)',
        boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.1)',
        '&:before': {
          position: 'absolute',
          content: '""',
          height: '10px',
          width: '10px',
          left: '4px',
          bottom: '3px',
          backgroundColor: 'white',
          webkitTransition: '.4s',
          transition: '.4s',
          borderRadius: '50%',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)'
        }
      }
    })
  }
};
