export const root = {
  css: {
    overflowY: 'unset',
    overflowX: 'unset',
    maxWidth: '1920px',
    margin: '0 auto',
    '*': {
      boxSizing: 'border-box'
    }
  },
  fontFamily: 'sans',
  fontSize: 'md',
  fontWeight: 'regular',
  lineHeight: 'default',
  letterSpacing: 'default'
};
