export const text = {
  fontWeight: 'regular',
  color: 'primaryText',
  letterSpacing: 'default',
  variants: {
    small: {
      fontSize: 'sm',
      color: 'textLight'
    },
    domain: {
      fontSize: 'sm',
      pr: 'sm',
      css: {
        textTransform: 'uppercase',
        color: 'rgba(255, 255, 255, 0.5)',
        display: 'inline-block'
      }
    },
    smallBold: {
      fontSize: 'xs',
      fontWeight: 'extraBold',
      color: 'textLight',
      css: {
        textTransform: 'uppercase'
      }
    }
  },
  css: ({ theme }) => ({
    /* eslint-disable id-length */
    a: {
      color: theme.colors.primary,
      textDecoration: 'none'
    }
    /* eslint-enable id-length */
  })
};
