import React from 'react';
import { themed, withTheme } from '@deity/falcon-ui';

export const IconRenderer = themed({
  tag: props => <svg {...props} />,

  defaultProps: {
    // https://stackoverflow.com/questions/18646111/disable-onfocus-event-for-svg-element
    focusable: 'false'
  },

  defaultTheme: {
    icon: {
      size: 'lg',
      fill: 'none',
      stroke: 'primaryText'
    }
  }
});

const IconComponent = props => {
  if (!props.theme || !props.theme.icons) return null;

  const { src, size, color, theme, fallback, ...rest } = props;
  const { icons } = theme;
  if (!src || !icons[src]) return fallback || null;
  const { icon, size: defSize, ...otherProps } = icons[src];
  const asPx = parseFloat(size || defSize);
  const pxSize = Number.isNaN(asPx) ? theme.spacing[size || defSize || 'md'] : asPx;

  return (
    <IconRenderer
      as={icon}
      stroke={color}
      {...otherProps}
      {...rest}
      style={{ width: pxSize, height: pxSize, ...otherProps.style, ...props.style }}
    />
  );
};

export const Icon = withTheme(IconComponent);
