import gql from 'graphql-tag';
import Query from '../GraphQL/Query';

export const BACKEND_CONFIG = gql`
  query BackendConfig {
    backendConfig {
      locales
      activeLocale
    }
  }
`;

export class BackendConfigQuery extends Query {
  static defaultProps = {
    query: BACKEND_CONFIG
  };
}
