import dropDownIndicator from '../../assets/icons/custom/chevron-down.svg';

export const select = {
  pl: 'sm',
  pr: 'lg',
  borderRadius: 'xs',
  fontSize: 'md',
  css: ({ theme }) => ({
    height: '40px',
    appearance: 'none',
    backgroundColor: 'rgba(55, 60, 79, 0.05)',
    backgroundImage: `url(${dropDownIndicator})`,
    backgroundPosition: 'right 10px center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    paddingTop: '9px',
    paddingBottom: '7px',
    border: 'none',
    '&::placeholder': {
      /* modern browser */
      color: theme.colors.textLight,
      fontSize: theme.fontSizes.md,
      lineHeight: 1.3
    },
    '&:focus': {
      outline: 'none',
      borderColor: theme.colors.secondary
    },
    '&:invalid': {
      color: theme.colors.textLight
    },
    '&[invalid=true]': {
      border: `1px solid ${theme.colors.error}`
    },
    '&:disabled': {
      opacity: '0.7'
    }
  }),
  variants: {
    selector: {
      css: ({ theme }) => ({
        backgroundColor: `${theme.colors.white} !important`
      })
    }
  }
};
