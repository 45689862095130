import React from 'react';
import { BackendConfigQuery } from './BackendConfigQuery';

export const LocaleContext = React.createContext({
  locale: 'nl-NL',
  localeFallback: 'en',
  locales: ['en', 'nl']
});

export const LocaleProvider = ({ children, ...props }) => (
  <BackendConfigQuery>
    {({ backendConfig: { activeLocale: locale } }) => (
      <LocaleContext.Provider value={{ locale, ...props }}>{children}</LocaleContext.Provider>
    )}
  </BackendConfigQuery>
);
