import { plans as devPlans } from './devPlanInfo';
import { plans as prodPlans } from './prodPlanInfo';
import { features } from './planFeatures';

let stripeMode = 'dev';

export const getCorrectPlanInfo = () => {
  if (stripeMode === 'prod') {
    return prodPlans;
  }
  return devPlans;
};

export const getCorrectFeatureInfo = () => {
  return features;
};

export const planOrder = [
  'pretrial',
  'free',
  'basic_month',
  'bronze_month',
  'silver_month',
  'basic_year',
  'bronze_year',
  'silver_year',
  'business_month',
  'gold_month',
  'business_year',
  'gold_year',
  'unlimited_month',
  'unlimited_year'
];
export const recommendPlan = 'business';

export const getPlanNameByID = id => {
  const planName = [prodPlans, devPlans]
    .map(plans =>
      Object.keys(plans).find(name => {
        const plan = plans[name];
        return (
          plan.id === id || (plan.month && plan.month.ids.includes(id)) || (plan.year && plan.year.ids.includes(id))
        );
      })
    )
    .find(name => name != null);
  return planName || 'Custom';
};

export const getPlanInfo = input => getCorrectPlanInfo()[input] || getCorrectPlanInfo()[getPlanNameByID(input)];

export const isUpgrade = (upgradePlan, currentPlan) => {
  const curIndex = planOrder.indexOf(currentPlan);
  const planIndex = planOrder.indexOf(upgradePlan);
  return planIndex > curIndex;
};

export const getFeatureForPlan = (feature, plan) => {
  const featureObject = features.find(e => e.type === feature);
  return featureObject.plans.find(e => e.name === plan);
};

// export const getTaxRateId = () => {
//   if (stripeMode === 'prod') {
//     return 'txr_1EVYzpI0jsznKlju81U4dmUv';
//   }
//   return 'txr_1EVJwZI0jsznKljuZT20jnWd';
// };

// export const getZeroTaxRateId = () => {
//   if (stripeMode === 'prod') {
//     return 'txr_1EajHnI0jsznKljutvvIe6zG';
//   }
//   return 'txr_1Eb3vkI0jsznKljuruZIpzoT';
// };

export const init = mode => {
  console.log('SET STRIPE MODE', mode);
  stripeMode = mode;
};
