export const dropdown = {
  p: 'none',
  variants: {
    domain: {
      css: {
        display: 'inline-block',
        width: 200,
        border: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.5)'
      }
    }
  }
};
