import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { LoaderOverlay } from '../Loader/LoaderOverlay';
import { GET_CUSTOMER_TYPE } from '../Customer/CustomerQuery';

export const ProtectedRoute = ({ component, redirectTo, ...rest }) => {
  const { data: customerData, loading } = useQuery(GET_CUSTOMER_TYPE);

  if (loading) {
    return <LoaderOverlay />;
  }

  return (
    <Route
      {...rest}
      render={props => {
        const { location } = props;
        const redirectUri = sessionStorage.getItem('redirect');
        sessionStorage.removeItem('redirect');
        if (!customerData?.customer?.instance || customerData?.customer?.instance.type.toLowerCase() === 'unknown') {
          // Redirect to onboarding
          return <Redirect to={{ pathname: '/onboarding', state: location.state }} />;
        }
        if (customerData?.customer && customerData?.customer.instance.type !== 'unknown' && redirectUri) {
          return <Redirect to={redirectUri} />;
        }
        if (customerData?.customer) return React.createElement(component, props);
        // No customer
        // Set redirect path and redirect to the provided path to authenticate
        sessionStorage.setItem('redirect', `${location.pathname}${location.search}`);

        return (
          <Redirect
            to={{
              pathname: redirectTo
            }}
          />
        );
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  redirectTo: '/login'
};

ProtectedRoute.propTypes = {
  ...Route.propTypes,
  redirectTo: PropTypes.string
};
