export const dropdownMenu = {
  variants: {
    domain: {
      color: 'primaryText',
      css: ({ theme }) => ({
        textAlign: 'left',
        '& > :hover': {
          color: theme.colors.secondaryText
        }
      })
    }
  }
};
