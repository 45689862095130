export const list = {
  css: ({ theme }) => ({
    listStyleType: 'disc',
    paddingLeft: theme.spacing.sm,
    marginTop: theme.spacing.xs,
    marginBottom: theme.spacing.xs
  }),
  variants: {
    pager: {
      my: 'sm',
      p: 'xs',
      bg: 'white',
      borderRadius: 'sm',
      boxShadow: 'xs',
      css: ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 'max-content',
        marginLeft: 'auto',
        li: {
          marginLeft: theme.spacing.sm,
          marginRight: theme.spacing.sm,
          display: 'flex',
          alignItems: 'center'
        },
        'li.next, li.previous, li.first, li.last': {
          marginLeft: theme.spacing.sm,
          marginRight: theme.spacing.sm
        },
        'li.current': {
          backgroundColor: theme.colors.primary,
          margin: 0,
          paddingLeft: theme.spacing.sm,
          paddingRight: theme.spacing.sm,
          paddingTop: theme.spacing.xs,
          paddingBottom: theme.spacing.xs,
          color: theme.colors.white,
          borderRadius: theme.borderRadius.xs
        },
        button: {
          background: 'none',
          color: theme.colors.primaryText,
          padding: 0,
          '&:hover:enabled': {
            background: 'none',
            boxShadow: 'none'
          },
          '&:disabled': {
            cursor: 'default'
          },
          '.themed-icon, svg': {
            stroke: theme.colors.primaryText
          }
        }
      })
    },
    pagerSmall: {
      my: 'md',
      mx: 'sm',
      css: ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'max-content',
        'li.next button, li.previous button': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme.colors.contextBg,
          borderRadius: theme.borderRadius.xs,
          padding: theme.spacing.xxs,
          color: theme.colors.textGrey,
          boxShadow: 'none',
          '&:hover': {
            background: theme.colors.contextBg,
            boxShadow: 'none'
          },
          svg: {
            stroke: theme.colors.textGrey
          }
        },
        '.current': {
          fontSize: theme.fontSizes.sm,
          color: theme.colors.textGrey,
          stroke: theme.colors.textGrey,
          marginLeft: theme.spacing.xs,
          marginRight: theme.spacing.xs
        }
      })
    }
  }
};
