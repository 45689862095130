export const menu = {
  bg: 'tableTopHeader',
  color: 'secondaryText',
  css: ({ theme }) => ({
    minHeight: '100%',
    '&.subMenu': {
      backgroundColor: theme.colors.primaryDark
    },
    '.category': {
      position: 'relative',
      color: theme.colors.secondaryText,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none'
      },
      '&:before': {
        content: '""',
        width: 4,
        top: 8,
        bottom: 8,
        position: 'absolute',
        borderTopRightRadius: theme.borderRadius.large,
        borderBottomRightRadius: theme.borderRadius.large,
        left: 0,
        backgroundColor: theme.colors.secondaryDark
      },
      '&.active': {
        position: 'relative',
        backgroundColor: theme.colors.secondaryLight,
        '&:before': {
          backgroundColor: theme.colors.primary
        }
      },
      '> li': {
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm
      },
      span: {
        fontWeight: theme.fontWeights.regular
      },
      '&.subCategory': {
        textDecoration: 'none',
        display: 'block',
        '&.active': {
          svg: {
            stroke: theme.colors.primary
          }
        },
        '&:before': {
          display: 'none'
        },
        ' span': {
          color: theme.colors.secondaryText,
          fontWeight: theme.fontWeights.regular,
          fontSize: theme.fontSizes.sm
        },
        '> *': {
          padding: theme.spacing.xs,
          paddingLeft: 2 * theme.spacing.md + theme.spacing.sm
        },
        svg: {
          stroke: theme.colors.textGrey,
          marginRight: 0
        }
      }
    }
  })
};
