export const link = {
  variants: {
    disabled: {
      css: {
        display: 'inline-block',
        opacity: 0.5,
        pointerEvents: 'none',
        cursor: 'not-allowed'
      }
    }
  }
};
