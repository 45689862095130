import { getClientConfigResolver } from '@deity/falcon-front-kit';

/**
 * Defines client-side state resolvers
 */

export default {
  data: {},

  resolvers: {
    Query: {
      clientConfig: getClientConfigResolver
    },
    Mutation: {}
  }
};
