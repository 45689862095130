export const numberInputCss = theme => ({
  '.themed-number-input.themed': {
    height: '100%',
    button: {
      background: theme.colors.defaultBorder,
      color: theme.colors.primaryText,
      transform: 'scale(0.7)',
      width: '40px',
      '&:hover': {
        background: theme.colors.defaultBorder,
        color: theme.colors.textGrey
      },
      '&.-inner-input-step-up-element': {
        '&:active': {
          transform: 'scale(0.8)'
        }
      }
    },
    input: {
      border: theme.borders.bold,
      borderRadius: theme.borderRadius.xs,
      borderColor: theme.colors.defaultBorder,
      height: '100%',
      width: '40px',
      outline: 'none'
    }
  }
});
