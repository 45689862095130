export const checkbox = {
  border: 'none',
  borderRadius: 'xs',
  css: ({ isminwidth, theme }) => ({
    outline: '0!important',
    backgroundColor: 'rgba(55, 60, 79, 0.05)',
    ...(isminwidth && { minWidth: '24px' }),
    'input + .-inner-checkbox-frame': {
      border: 'none',
      borderRadius: theme.borderRadius.xs,
      '.-inner-checkbox-icon': {
        stroke: theme.colors.textGrey,
        width: '75%'
      }
    },
    'input:hover + .-inner-checkbox-frame': {
      border: 'none'
    },
    'input:checked + .-inner-checkbox-frame': {
      backgroundColor: 'rgba(55, 60, 79, 0.05)',
      border: 'none'
    },
    'input:checked:hover + .-inner-checkbox-frame': {
      backgroundColor: 'rgba(55, 60, 79, 0.05)',
      border: 'none'
    },
    'input:focus': {
      outline: '0!important'
    }
  })
};
