export const backdrop = {
  opacity: 0.4,
  variants: {
    modal: {
      css: ({ theme }) => ({
        backgroundColor: '#000000',
        animation: `0.25s cubic-bezier(0.5,0,0.2,1) forwards ${theme.keyframes.fadeInBackdrop}`,
        '&.reversed': {
          animation: `0.25s cubic-bezier(0.5,0,0.2,1) forwards ${theme.keyframes.fadeOutBackdrop}`
        }
      })
    },
    campaign: {
      css: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .8)',
        width: '100%',
        height: '100%',
        content: '""',
        zIndex: 4,
        transition: 'all 0.5s ease',
        opacity: 1
      }
    }
  }
};
