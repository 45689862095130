import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const HubspotChat = () => {
  const location = useLocation();
  /* eslint-disable no-underscore-dangle */
  const hubspotObject = window._hsq || [];
  const { HubSpotConversations } = window || [];

  useEffect(() => {
    hubspotObject.push(['setPath', location.pathname]);
    hubspotObject.push(['trackPageView']);
    if (HubSpotConversations && HubSpotConversations.widget) {
      HubSpotConversations.widget.refresh();
    }
  }, [HubSpotConversations, hubspotObject, location]);

  return null;
};
