import React from 'react';
import {
  AlertCircle,
  AlertTriangle,
  ArrowUp,
  ArrowDown,
  ArrowRightCircle,
  ArrowRight,
  ArrowLeft,
  BarChart2,
  Bell,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronRight,
  ChevronLeft,
  Clock,
  CreditCard,
  Crosshair,
  Download,
  Edit,
  Edit2,
  Edit3,
  Eye,
  GitMerge,
  Globe,
  HelpCircle,
  Info,
  Layers,
  Layout,
  Link,
  Lock,
  LogOut,
  Mail,
  MessageSquare,
  Monitor,
  MoreVertical,
  MousePointer,
  PauseCircle,
  PieChart,
  PlayCircle,
  Plus,
  PlusCircle,
  RefreshCw,
  Repeat,
  RotateCw,
  Save,
  Send,
  Server,
  Settings,
  SkipForward,
  SkipBack,
  Sliders,
  Smile,
  Star,
  StopCircle,
  Target,
  Trash2,
  Users,
  UserCheck,
  User,
  Upload,
  Unlock,
  UserPlus,
  X,
  XCircle,
  Zap
} from 'react-feather';
import Home from '../assets/icons/custom-components/Home';
import Receipt from '../assets/icons/custom-components/Receipt';
import TimeAhead from '../assets/icons/custom-components/TimeAhead';
import Infinite from '../assets/icons/custom-components/Infinite';
import CC_AE from '../assets/icons/cc-providers/cc_provider-american_express@2x.png';
import CC_VISA from '../assets/icons/cc-providers/cc_provider-visa@2x.png';
import CC_MA from '../assets/icons/cc-providers/cc_provider-mastercard@2x.png';
import CC_DISC from '../assets/icons/cc-providers/cc_provider-discover@2x.png';
import CC_DC from '../assets/icons/cc-providers/cc_provider-diners_club@2x.png';
import CC_WU from '../assets/icons/cc-providers/cc_provider-western_union@2x.png';
import CC_JCB from '../assets/icons/cc-providers/cc_provider-jcb@2x.png';
import CC_UNKNOWN from '../assets/icons/cc-providers/cc_provider-unknown@2x.png';
import PushProLogo from '../assets/icons/logo-primary-inverted.svg';
import PushProLogoWhite from '../assets/icons/logo-mono-inverted.svg';
import PushProLogoV2 from '../assets/logo.svg';
import Rocket from '../assets/icons/rocket.svg';
import Wordpress from '../assets/icons/extensions/wordpress_white.svg';
import Magento from '../assets/icons/extensions/magento_white.svg';
import BigCommerce from '../assets/icons/extensions/bigcommerce_white.svg';
import Typo3 from '../assets/icons/extensions/typo3_white.svg';
import Woocommerce from '../assets/icons/extensions/woocommerce_white.svg';
import ValidationSuccess from '../assets/icons/login/icon-success.svg';
import ValidationError from '../assets/icons/login/icon-error.svg';

export const icons = {
  logo: {
    icon: props => <img src={PushProLogo} alt="PushPro Logo" {...props} />,
    stroke: 'none'
  },
  logoV2: {
    icon: props => <img src={PushProLogoV2} alt="PushPro Logo" {...props} />,
    stroke: 'none'
  },
  logoWhite: {
    icon: props => <img src={PushProLogoWhite} alt="PushPro Logo" {...props} />,
    stroke: 'none'
  },
  loader: {
    icon: props => (
      <svg viewBox="0 0 50 50" {...props}>
        <path
          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
          transform="rotate(241.969 25 25)"
        >
          <animateTransform
            attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    ),
    size: 'xxl',
    stroke: 'transparent',
    fill: 'primary'
  },
  loaderInline: {
    icon: props => (
      <svg viewBox="0 0 100 100" {...props}>
        <circle
          fill="none"
          strokeWidth="8"
          strokeMiterlimit="20"
          strokeDasharray="14.2472,14.2472"
          cx="50"
          cy="50"
          r="47"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="5s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    ),
    size: 'md',
    m: 'md',
    stroke: 'primary',
    fill: 'none'
  },
  validationSuccess: { icon: props => <img src={ValidationSuccess} alt="success" {...props} /> },
  validationError: { icon: props => <img src={ValidationError} alt="error" {...props} /> },
  user: { icon: User },
  questionmark: { icon: HelpCircle },
  home: { icon: Home, stroke: 'none' },
  billing: { icon: Receipt, stroke: 'none' },
  infinite: { icon: Infinite, stroke: 'none', fill: 'currentColor' },
  update: { icon: TimeAhead, stroke: 'none' },
  users: { icon: Users },
  userCheck: { icon: UserCheck },
  upload: { icon: Upload },
  push: { icon: MessageSquare },
  analyze: { icon: BarChart2 },
  chart: { icon: BarChart2 },
  web: { icon: Globe },
  bell: { icon: Bell },
  download: { icon: Download },
  card: { icon: CreditCard },
  crosshair: { icon: Crosshair },
  draft: { icon: Edit },
  pencil: { icon: Edit2 },
  info: { icon: Info },
  chevronLeft: { icon: ChevronLeft },
  chevronRight: { icon: ChevronRight },
  arrowUp: { icon: ArrowUp },
  arrowDown: { icon: ArrowDown },
  arrowLeft: { icon: ArrowLeft },
  arrowRight: { icon: ArrowRight },
  arrowRightCircle: { icon: ArrowRightCircle },
  settings: { icon: Settings },
  emoji: { icon: Smile, size: 'sm' },
  star: { icon: Star },
  monitor: { icon: Monitor },
  link: { icon: Link },
  time: { icon: Clock },
  target: { icon: Target },
  segment: { icon: PieChart },
  send: { icon: Send },
  save: { icon: Save },
  server: { icon: Server },
  buttonArrowLeft: {
    icon: ChevronLeft,
    size: 'md',
    ml: 'xs'
  },
  close: {
    icon: X,
    css: {
      cursor: 'pointer'
    }
  },
  cross: { icon: X },
  mail: { icon: Mail },
  logout: { icon: LogOut },
  circleDanger: { icon: AlertCircle, size: 'md' },
  circleAdd: { icon: PlusCircle, size: 'md' },
  circleRemove: { icon: XCircle, size: 'md' },
  circleCheck: { icon: CheckCircle, size: 'md' },
  triangleDanger: { icon: AlertTriangle, size: 'md' },
  delete: { icon: Trash2 },
  check: { icon: Check },
  checkSquare: { icon: CheckSquare },
  view: { icon: Eye },
  add: { icon: Plus },
  edit: { icon: Edit3 },
  refresh: { icon: RotateCw },
  renew: { icon: RefreshCw },
  nextPage: { icon: ChevronRight },
  prevPage: { icon: ChevronLeft },
  lastPage: { icon: SkipForward },
  firstPage: { icon: SkipBack },
  more: { icon: MoreVertical },
  play: { icon: PlayCircle },
  pause: { icon: PauseCircle },
  stop: { icon: StopCircle },
  layers: { icon: Layers },
  layout: { icon: Layout },
  intervalbased: { icon: Clock },
  pagevisited: { icon: Link },
  newsubscriber: { icon: UserPlus },
  start: { icon: PlayCircle },
  end: { icon: StopCircle },
  hold: { icon: PauseCircle },
  sent: { icon: MessageSquare },
  sendtemplate: { icon: MessageSquare },
  decision: { icon: GitMerge },
  sliders: { icon: Sliders },
  repeat: { icon: Repeat },
  lock: { icon: Lock },
  unlock: { icon: Unlock },
  zap: { icon: Zap },
  pointer: { icon: MousePointer },
  more_v: { icon: MoreVertical },
  rocket: {
    icon: props => <img src={Rocket} alt="Rocket" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_AE: {
    icon: props => <img src={CC_AE} alt="American Express" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_MA: {
    icon: props => <img src={CC_MA} alt="MasterCard" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_VISA: {
    icon: props => <img src={CC_VISA} alt="VISA" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_JCB: {
    icon: props => <img src={CC_JCB} alt="JCB" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_DC: {
    icon: props => <img src={CC_DC} alt="Diners Club" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_DISC: {
    icon: props => <img src={CC_DISC} alt="Discover" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_WU: {
    icon: props => <img src={CC_WU} alt="Western Union" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  CC_UNKNOWN: {
    icon: props => <img src={CC_UNKNOWN} alt="Unknown creditcard provider" {...props} />,
    stroke: 'none',
    width: '146px'
  },
  wordpress: {
    icon: props => <img src={Wordpress} alt="Wordpress Logo" {...props} />,
    stroke: 'none',
    width: '146px',
    height: 'auto'
  },
  magento: {
    icon: props => <img src={Magento} alt="Magento Logo" {...props} />,
    stroke: 'none'
  },
  bigcommerce: {
    icon: props => <img src={BigCommerce} alt="BigCommerce Logo" {...props} />,
    stroke: 'none'
  },
  typo3: {
    icon: props => <img src={Typo3} alt="TYPO3 Logo" {...props} />,
    stroke: 'none'
  },
  woocommerce: {
    icon: props => <img src={Woocommerce} alt="WooCommerce Logo" {...props} />,
    stroke: 'none'
  }
};
