import gql from 'graphql-tag';
import Query from '../GraphQL/Query';

export const GET_CUSTOMER_DETAILS = gql`
  query Customer {
    customer {
      stripe {
        shipping {
          address {
            city
            country
            line1
            postal_code
            state
          }
          name
        }
        email
        metadata
        subscription {
          plan {
            id
            interval
          }
          status
          trial_end
          trial_start
        }
        payment_method {
          id
          card {
            brand
            checks {
              address_line1_check
              address_postal_code_check
              cvc_check
            }
            country
            exp_month
            exp_year
            fingerprint
            funding
            last4
            three_d_secure_usage {
              supported
            }
          }
          billing_details {
            name
          }
        }
        tax_exempt
        tax_ids {
          data {
            type
            value
          }
        }
      }
      user {
        role
        user_uuid
        roles_per_site {
          site_uuid
          role
        }
        first_name
        last_name
        name
        given_name
        email
        picture
      }
      instance {
        instance_uuid
        type
      }
      is_trial
      had_trial
      stats {
        subscribers_count
        subscribers_removed_count
      }
    }
  }
`;

export class CustomerQuery extends Query {
  static defaultProps = {
    query: GET_CUSTOMER_DETAILS,
    fetchPolicy: 'network-only'
  };
}

export const GET_CUSTOMER_TYPE = gql`
  query Customer {
    customer {
      instance {
        type
      }
      is_trial
      had_trial
    }
  }
`;

export class CustomerTypeQuery extends Query {
  static defaultProps = {
    query: GET_CUSTOMER_TYPE,
    fetchPolicy: 'network-only'
  };
}

export const GET_CUSTOMER_STATS = gql`
  query CustomerStats {
    customerStats {
      instance {
        instance_uuid
        type
      }
      stats {
        subscribers_count
        subscribers_removed_count
      }
      task_stats {
        messages
        message_send
        message_received
        message_closed
        message_clicked
      }
    }
  }
`;

export class CustomerStatsQuery extends Query {
  static defaultProps = {
    query: GET_CUSTOMER_STATS,
    fetchPolicy: 'network-only'
  };
}

export const GET_TOTAL_SUBSCRIBERS = gql`
  query TotalSubscribers {
    totalSubscribers {
      subscribers
    }
  }
`;

export class TotalSubscribersQuery extends Query {
  static defaultProps = {
    query: GET_TOTAL_SUBSCRIBERS,
    fetchPolicy: 'network-only'
  };
}
