export const table = {
  lineHeight: 'table',
  css: ({ theme }) => ({
    borderStyle: 'unset',
    borderCollapse: 'unset',
    thead: {
      backgroundColor: theme.colors.primaryDark,
      th: {
        color: theme.colors.textLight,
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        lineHeight: theme.lineHeights.large,
        whiteSpace: 'nowrap',
        paddingLeft: theme.spacing.tableMiddle,
        paddingRight: theme.spacing.tableMiddle,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs
      }
    },
    tbody: {
      td: {
        color: theme.colors.primaryText,
        paddingLeft: theme.spacing.sm,
        paddingRight: theme.spacing.sm,
        paddingTop: theme.spacing.xs,
        paddingBottom: theme.spacing.xs,
        border: theme.borders.regular,
        borderColor: theme.colors.defaultBorder,
        background: theme.colors.white,
        whiteSpace: 'nowrap',
        textAlign: 'left',
        verticalAlign: 'middle'
      }
    },
    [`@media only screen and (max-width: ${theme.breakpoints.md - 1}px)`]: {
      thead: {
        th: {
          whiteSpace: 'nowrap',
          padding: `${theme.spacing.tableXSmall}px ${theme.spacing.tableSmall}px`
        }
      },
      tbody: {
        td: {
          padding: `${theme.spacing.tableXSmall}px ${theme.spacing.tableSmall}px`
        }
      }
    }
  }),
  variants: {
    features: {
      lineHeight: 'tableLarge',
      borderCollapse: 'collapse',
      css: ({ theme }) => ({
        thead: {
          backgroundColor: theme.colors.transparent,
          color: theme.colors.tableTopHeader,
          fontSize: theme.fontSizes.md,
          textTransform: 'uppercase',
          th: {
            textAlign: 'center',
            whiteSpace: 'nowrap'
          }
        },
        tbody: {
          color: theme.colors.primaryDark,
          fontSize: theme.fontSizes.md,
          th: {
            fontSize: theme.fontSizes.sm,
            fontWeight: theme.fontWeights.semibold,
            whiteSpace: 'nowrap'
          },
          td: {
            textAlign: 'center',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle'
          }
        }
      })
    },
    light: {
      css: ({ theme }) => ({
        thead: {
          backgroundColor: theme.colors.transparent,
          fontSize: theme.fontSizes.xs,
          lineHeight: theme.lineHeights.small,
          textTransform: 'uppercase',
          th: {
            paddingLeft: 0,
            paddingBottom: theme.spacing.xsm,
            fontWeight: theme.fontWeights.extraBold,
            color: theme.colors.textDark,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            borderBottom: theme.borders.regular,
            borderColor: theme.colors.borderGrey
          }
        },
        tbody: {
          color: theme.colors.primaryDark,
          fontSize: theme.fontSizes.md,
          td: {
            fontSize: theme.fontSizes.sm,
            padding: theme.spacing.tableMiddle,
            paddingLeft: 0,
            textAlign: 'left',
            whiteSpace: 'nowrap',
            verticalAlign: 'middle',
            borderBottom: theme.borders.regular,
            borderColor: theme.colors.defaultBorder
          }
        }
      })
    },
    modern: {
      css: ({ theme }) => ({
        borderSpacing: `0 ${theme.spacing.xs}px`,
        borderCollapse: 'seperate',
        thead: {
          backgroundColor: 'transparent',
          th: {
            backgroundColor: 'transparent',
            textTransform: 'uppercase',
            color: theme.colors.textLight,
            fontSize: theme.fontSizes.xxs,
            fontWeight: theme.fontWeights.extraBold,
            padding: `0 ${theme.spacing.tableMiddle}px`
          }
        },
        tbody: {
          td: {
            backgroundColor: theme.colors.white,
            color: theme.colors.textLight,
            border: theme.borders.regular,
            borderColor: theme.colors.defaultBorder,
            lineHeight: 1,
            borderRight: 'none',
            borderLeft: 'none',
            padding: `${theme.spacing.md}px ${theme.spacing.tableMiddle}px`,
            '&:first-of-type': {
              borderBottomLeftRadius: theme.borderRadius.sm,
              borderTopLeftRadius: theme.borderRadius.sm,
              borderLeft: theme.borders.regular,
              borderColor: theme.colors.defaultBorder
            },
            '&:last-child': {
              borderBottomRightRadius: theme.borderRadius.sm,
              borderTopRightRadius: theme.borderRadius.sm,
              borderRight: theme.borders.regular,
              borderColor: theme.colors.defaultBorder
            }
          }
        },
        [`@media only screen and (min-width: ${theme.breakpoints.xs}px)`]: {
          overflowY: 'unset'
        }
      })
    }
  }
};
