export const appLayout = {
  width: '1400px',
  css: ({ theme }) => ({
    '&.home': {
      background: `linear-gradient(to bottom right, ${theme.colors.primaryLight}, ${theme.colors.primary})`,
      color: theme.colors.primaryText,
      width: '100%',
      height: '100%',
      minHeight: '100vh'
    }
  })
};
