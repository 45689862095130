import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { LoaderOverlay } from '../Loader/LoaderOverlay';

const getErrorCode = error => {
  if (error) {
    const { graphQLErrors } = error;
    if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
      const { extensions = {} } = graphQLErrors[0];
      const { code } = extensions;
      return code;
    }
  }
  return undefined;
};

const HookQuery = ({ children, query, passLoading, ...restProps }) => {
  const { loading, error, data } = useQuery(query, { ...restProps });

  const errorCode = getErrorCode(error);
  if (error && (errorCode === 'UNAUTHENTICATED' || errorCode === 'CUSTOMER_TOKEN_EXPIRED')) {
    return <Redirect to="/logout" />;
  }
  if (!passLoading && loading) {
    return <LoaderOverlay />;
  }

  return children({
    ...data,
    loading,
    error
  });
};

/* eslint-disable react/prefer-stateless-function */
class Query extends React.Component {
  render() {
    return <HookQuery {...this.props} />;
  }
}

export default Query;
